import '@mantine/core/styles.css';
import React, { ReactNode, createContext, useContext } from 'react';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { theme } from '../theme';

// Create a context that can hold the Mantine theme
const ModestUiContext = createContext<MantineThemeOverride>(theme);

export const ModestUiProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <MantineProvider theme={theme}>
      <ModestUiContext.Provider value={theme}>{children}</ModestUiContext.Provider>
    </MantineProvider>
  );
};

export const useTheme = (): MantineThemeOverride => {
  const theme = useContext(ModestUiContext);

  if (!theme) {
    throw new Error('useTheme must be used within a ModestUiProvider that provides a theme');
  }

  return theme;
};
