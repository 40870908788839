import { Title as MaintineTitle, TitleProps as MantineTitleProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

type TitleProps = {
  color?: MantineTitleProps['c'];
  size?: MantineTitleProps['size'];
  order: MantineTitleProps['order'];
  children: ReactNode;
};

export const Title: FC<TitleProps> = ({ color, children, ...props }) => (
  <MaintineTitle c={color} {...props}>
    {children}
  </MaintineTitle>
);
