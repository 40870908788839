import React from "react";

export const Seo = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
    </>
  );
};
