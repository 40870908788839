import { StyledLink } from "./styledLink.component";
import { Text } from "@modest/ui";

type NavLinkProps = {
  variant?: "active" | "plain";
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const NavLinks = ({ variant, onClick }: NavLinkProps) => {
  const links = [
    { url: "/", label: "Home" },
    { url: "/services/", label: "Services" },
    { url: "/faq/", label: "FAQ" },
    { url: "/contact/", label: "Contact" },
  ];

  return links.map((link) => (
    <StyledLink
      to={link.url}
      key={link.url}
      variant={variant}
      onClick={onClick}
    >
      <Text color="white">{link.label}</Text>
    </StyledLink>
  ));
};
