import React, { ReactNode, FC, ReactElement, MouseEvent, cloneElement } from 'react';
import { Container, Group, Burger, Drawer, Stack, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { css } from '@emotion/react';

const containerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

type HorizontalNavbarProps = {
  brandArea?: ReactNode;
  children?: ReactElement<{ onClick?: (event: MouseEvent<HTMLElement>) => void }>;
};

export const HorizontalNavbar: FC<HorizontalNavbarProps> = ({ children, brandArea }) => {
  const theme = useMantineTheme();
  const [opened, { open, close, toggle }] = useDisclosure(false);
  const background = location.pathname === '/' ? undefined : theme.colors['modern-blue'][6];

  const handleLinkClick = (
    event: MouseEvent<HTMLElement>,
    onClick?: (event: MouseEvent<HTMLElement>) => void
  ) => {
    if (onClick) {
      onClick(event);
    }
    close();
  };

  const childrenWithCloseDrawer = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const originalOnClick = child.props.onClick;
      const key = child.key != null ? child.key : Math.random().toString(36).substring(2, 9);

      return cloneElement(child, {
        key,
        onClick: (event: MouseEvent<HTMLElement>) => handleLinkClick(event, originalOnClick),
      });
    }
    return child;
  });

  return (
    <Container fluid style={{ backgroundColor: background }}>
      <Container css={containerStyle} size="lg">
        {brandArea}
        <Group gap={5} style={{ visibility: opened ? 'hidden' : 'visible' }} visibleFrom="xs">
          {childrenWithCloseDrawer}
        </Group>
        <Drawer
          position="right"
          opened={opened}
          onClose={close}
          title="Modest Maple Web Development"
          padding="xl"
          size="md"
          styles={{
            header: { backgroundColor: theme.colors['modern-blue'][6], color: 'white' }, // Sky Blue
            content: { backgroundColor: theme.colors['modern-blue'][6] },
            close: { color: 'white' },
          }}
        >
          <Stack>{childrenWithCloseDrawer}</Stack>
        </Drawer>
        <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" color="white" />
      </Container>
    </Container>
  );
};
