import { Textarea as MantineTextarea, TextareaProps as MantineTextareaProps } from '@mantine/core';
import { FC } from 'react';

type TextareaProps = {
  label?: MantineTextareaProps['label'];
  placeholder?: MantineTextareaProps['placeholder'];
  required?: MantineTextareaProps['required'];
  minRows?: MantineTextareaProps['minRows'];
  value?: MantineTextareaProps['value'];
  name?: MantineTextareaProps['name'];
  onChange?: MantineTextareaProps['onChange'];
};

export const Textarea: FC<TextareaProps> = (props) => <MantineTextarea {...props} />;
