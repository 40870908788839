import { css } from "@emotion/react";
import { Link } from "gatsby";
import React, { FC, ReactNode } from "react";

const underscoreStyle = css({
  display: "inline-block",
  lineHeight: "1.5",
  padding: "0.2rem 0.5rem",
  borderRadius: "4px",
  textDecoration: "none",
  fontSize: "14px",
  position: "relative",
  letterSpacing: "0.15em",

  "&:after": {
    background: "none repeat scroll 0 0 #fbd7d7", // Light Maple Red
    bottom: "0",
    content: '""',
    display: "block",
    height: "2px",
    position: "absolute",
    transition: "width 0.3s ease 0s, left 0.3s ease 0s",
  },
});

const activeLinkStyle = css({
  "&:after": {
    width: "100%",
    left: "0",
  },
});

const linkStyle = css({
  "&:after": {
    height: "2px",
    left: "50%",
    width: "0",
  },

  "&:hover:after": {
    width: "100%",
    left: "0",
  },
});

interface LinkProps {
  variant?: "active" | "plain";
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children: ReactNode;
}

export const StyledLink: FC<LinkProps> = ({
  variant = "active",
  to,
  onClick,
  children,
}) => {
  const isActive = location.pathname === to;

  if (variant === "plain")
    return (
      <Link to={to} css={[linkStyle]} onClick={onClick}>
        {children}
      </Link>
    );

  return (
    <Link
      to={to}
      onClick={onClick}
      css={[underscoreStyle, isActive ? activeLinkStyle : linkStyle]}
    >
      {children}
    </Link>
  );
};
