import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';
import { Text } from '@mantine/core';

// Container for the entire steps section
const Container = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Each individual step container
const StepContainer = styled.div`
  text-align: center;
  position: relative;
`;

// Line between steps
const StepLine = styled.div`
  position: absolute;
  top: 15%;
  left: 80%;
  width: 150px;
  height: 1px;
  background-color: #ddd;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    display: none;
  }
`;

// Circle indicator for each step
const StepCircle = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #1e87fe;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140%;
    height: 140%;
    border-radius: 50%;
    background-color: rgba(30, 135, 254, 0.5);
    transform: translate(-50%, -50%);
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  }

  &::after {
    width: 180%;
    height: 180%;
    background-color: rgba(30, 135, 254, 0.3);
  }

  &:hover {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    &::before,
    &::after {
      opacity: 0.7;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;

// Inner circle for the number
const CircleNumber = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  z-index: 1; /* Ensure this is above the pseudo-elements */
`;

// Title for each step
const StepTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
`;

// Types for the props
interface StepProps {
  number: string;
  title: string;
  children: ReactNode;
  hasLine?: boolean;
}

interface StepsProps {
  children: ReactNode;
}

// Compound component definition
export const Steps: FC<StepsProps> & { Step: FC<StepProps> } = ({ children }) => {
  return <Container>{children}</Container>;
};

// Step sub-component
const Step: FC<StepProps> = ({ number, title, children, hasLine = false }) => (
  <StepContainer>
    <StepCircle>
      <CircleNumber>{number}</CircleNumber>
    </StepCircle>
    <StepTitle>{title}</StepTitle>
    <Text>{children}</Text>
    {hasLine && <StepLine />}
  </StepContainer>
);

// Assign sub-component to Steps
Steps.Step = Step;
