import React, { ReactNode, FC } from 'react';
import { css } from '@emotion/react';
import { Container } from '@mantine/core';

const rootContainer = css({
  display: 'grid',
  gridTemplateRows: 'auto auto auto',
  minHeight: '100vh',
});

const bodyContainer = css({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  minHeight: '0',
});

const footerContainer = css({
  textAlign: 'center',
  fontSize: '14px',
  whiteSpace: 'nowrap',
});

const responsiveStyles = css({
  '@media (max-width: 375px)': {
    maxWidth: '375px',
  },
});

interface ContainerProps {
  children?: ReactNode;
}

const Header: FC<ContainerProps> = ({ children }) => (
  <header css={responsiveStyles}>{children}</header>
);
const Navbar: FC<ContainerProps> = ({ children }) => <nav>{children}</nav>;
const Body: FC<ContainerProps> = ({ children }) => (
  <main css={[bodyContainer, responsiveStyles]}>{children}</main>
);
const Footer: FC<ContainerProps> = ({ children }) => (
  <footer css={[footerContainer, responsiveStyles]}>{children}</footer>
);

export const AppShell: FC<ContainerProps> & {
  Header: typeof Header;
  Navbar: typeof Navbar;
  Body: typeof Body;
  Footer: typeof Footer;
} = ({ children }) => {
  return <div css={{ rootContainer }}>{children}</div>;
};

AppShell.Header = Header;
AppShell.Navbar = Navbar;
AppShell.Body = Body;
AppShell.Footer = Footer;
