import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';
import { FC } from 'react';

type TextInputProps = {
  label?: MantineTextInputProps['label'];
  placeholder?: MantineTextInputProps['placeholder'];
  required?: MantineTextInputProps['required'];
  value?: MantineTextInputProps['value'];
  name?: MantineTextInputProps['name'];
  onChange?: MantineTextInputProps['onChange'];
};

export const TextInput: FC<TextInputProps> = (props) => <MantineTextInput {...props} />;
