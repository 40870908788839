import React from "react";
import {
  AppShell,
  ModestUiProvider,
  PatternBackground,
  HorizontalNavbar,
  Text,
} from "@modest/ui";
import { Global, css } from "@emotion/react";
import { GatsbyBrowser } from "gatsby";
import { Footer } from "./src/modules/common/footer.component";
import { Logo, NavLinks } from "./src/modules/common";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => (
  <ModestUiProvider>
    <Global styles={{ body: { margin: "0px" } }} />
    {element}
  </ModestUiProvider>
);

const responsiveBodyStyle = css({
  "@media only screen and (min-width: 810px)": {
    margin: "0 40px",
  },

  "@media only screen and (min-width: 1200px)": {
    margin: "0 150px",
  },

  "@media only screen and (min-width: 1440px)": {
    margin: "0 260px",
  },

  "@media only screen and (min-width: 1920px)": {
    margin: "0 460px",
  },
  "@media only screen and (min-width: 2560px)": {
    margin: "100px 720px",
  },
});

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      <AppShell>
        <AppShell.Header>
          <HorizontalNavbar brandArea={<Logo />}>
            <NavLinks />
          </HorizontalNavbar>
        </AppShell.Header>
        <AppShell.Body>
          <div css={responsiveBodyStyle}>{element}</div>
        </AppShell.Body>
        <AppShell.Footer>
          <Footer />
        </AppShell.Footer>
      </AppShell>
      <PatternBackground color="sky-blue" />
    </>
  );
};
