import { MantineTheme, createTheme } from '@mantine/core';

/**
 * Modest Maple Color Scheme:

    Modern Blue (Primary)
        Hex: #007BFF
    Circuit Silver (Secondary)
        Hex: #A9BAC9
    Maple Red (Accent)
        Hex: #D32F2F
    Sky Blue (Tertiary)
      Hex: #5BC0DE
    Cloud White (Background)
        Hex: #F4F4F4
    Deep Space Black (Text)
        Hex: #333333

 */

export type ModestUiColors =
  | keyof MantineTheme['colors']
  | 'modern-blue'
  | 'circuit-silver'
  | 'maple-red'
  | 'sky-blue'
  | 'gray';

export const theme = createTheme({
  colors: {
    'modern-blue': [
      '#e4f5ff',
      '#cde6ff',
      '#9bccff',
      '#64afff',
      '#3997fe',
      '#1e87fe',
      '#0980ff',
      '#006de4',
      '#0061cd',
      '#0054b5',
    ],
    'circuit-silver': [
      '#e9f7ff',
      '#dfe9f1',
      '#c1ceda',
      '#a0b3c3',
      '#849cb0',
      '#728da5',
      '#6886a0',
      '#57738c',
      '#4a667f',
      '#395973',
    ],
    'maple-red': [
      '#ffebeb',
      '#fbd7d7',
      '#efacad',
      '#e47f7f',
      '#db5a5a',
      '#d64141',
      '#d43434',
      '#bc2727',
      '#a81f21',
      '#94141a',
    ],
    'sky-blue': [
      '#e1fbff',
      '#d1f1fb',
      '#a8e0f0',
      '#7bcee6',
      '#57bfdd',
      '#3fb5d8',
      '#2cb0d7',
      '#169abf',
      '#008aac',
      '#007898',
    ],
    gray: [
      '#f5f5f5',
      '#e7e7e7',
      '#cdcdcd',
      '#b2b2b2',
      '#9a9a9a',
      '#8b8b8b',
      '#848484',
      '#717171',
      '#656565',
      '#575757',
    ],
  },
  primaryColor: 'modern-blue',
  fontFamily: 'Quicksand, sans-serif',
  headings: {
    fontFamily: 'Quicksand, sans-serif',
  },
});
