import { Button as MantineButton, ButtonProps as MantineButtonProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

type CommonButtonProps = {
  backgroundColor?: MantineButtonProps['bg'];
  textColor?: MantineButtonProps['c'];
  type?: 'button' | 'submit' | 'reset';
  children?: ReactNode;
  size?: MantineButtonProps['size'];
  onClick?: () => void;
  loading?: MantineButtonProps['loading'];
};

interface DefaultButton extends CommonButtonProps {
  gradient?: never;
  variant?: 'default';
}

interface GradientButton extends CommonButtonProps {
  gradient: {
    to: string;
    from: string;
    deg?: number;
  };
  variant?: 'gradient';
}

type ButtonProps = DefaultButton | GradientButton;

export const Button: FC<ButtonProps> = ({
  backgroundColor,
  textColor = 'modern-blue',
  onClick,
  children,
  ...props
}) => (
  <MantineButton c={textColor} bg={backgroundColor} onClick={onClick} {...props}>
    {children}
  </MantineButton>
);
