import { css, keyframes } from '@emotion/react';
import { FC } from 'react';
import { ModestUiColors } from '../../theme';
import { useMantineTheme } from '@mantine/core';
import { MapleLeafPattern } from '@modest/shared-assets';

const panAnimation = keyframes`
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 100% 0%;
  }
`;

const patternBackground = css({
  backgroundImage: `url(${MapleLeafPattern})`,
  backgroundSize: '10%',
  position: 'fixed', // Change from 'absolute' to 'fixed'
  left: '50%',
  top: '0px',
  transform: 'translateX(-50%)', // Adjusted for correct centering
  zIndex: '1',
  height: '100vh', // Adjusted to '100vh' or use 'min-height' if content is taller
  width: '100%',
  minWidth: '1200px',
  opacity: '0.3',
  animation: `${panAnimation} 180s linear infinite`,
  willChange: 'background-position',
});

const startGradientOverlay = (color: string) =>
  css({
    background: 'radial-gradient(circle, transparent 75%, rgb(var(--dark-gold-rgb)))',
    backgroundColor: color,
    position: 'fixed', // Change from 'absolute' to 'fixed'
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100vh', // Adjusted to '100vh' or use 'min-height' if content is taller
    opacity: '0.9',
    zIndex: '-2',
  });

type PatternBackgroundColors = {
  color: ModestUiColors;
};

export const PatternBackground: FC<PatternBackgroundColors> = ({ color }) => {
  const theme = useMantineTheme();
  const modestColor = theme.colors[color] ? theme.colors[color][1] : theme.colors['gray'][1];

  return (
    <div css={startGradientOverlay(modestColor)}>
      <div css={patternBackground}></div>
    </div>
  );
};
