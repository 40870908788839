import { css } from "@emotion/react";
import { Text } from "@modest/ui";

const logoAndText = css({
  display: "flex",
  alignItems: "center",
  textAlign: "left",
});

export const Logo = () => (
  <div css={logoAndText}>
    <img src="/svg/logo.svg" height="100px" alt="logo" />
    <div>
      <Text size="lg" color="white" bold>
        Modest Maple
      </Text>
      <Text color="white">Web Development</Text>
    </div>
  </div>
);
