import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Logo } from "./logo.component";
import { Title, Text, VerticalStack } from "@modest/ui";
import { NavLinks } from "./navLinks";

const responsiveBodyStyle = css({
  backgroundColor: "#0061cd",

  "@media only screen and (min-width: 1025px)": {
    padding: "0 240px",
  },

  "@media only screen and (min-width: 1920px)": {
    padding: "0 460px",
  },

  "@media only screen and (min-width: 2560px)": {
    padding: "0 720px",
  },
});

const copyright = css({
  backgroundColor: "#0061cd",
  padding: "25px 0",
});

const containerStyle = css`
  display: flex;
  flex-direction: column;
`;

const svgStyle = css`
  display: block;
`;

const FooterContainer = styled.footer`
  color: #ffffff;
  padding: 40px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Section = styled.div`
  margin: 20px;

  h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #9bccff;
    }
  }

  p,
  span {
    margin: 0;
    color: #ffffff;
  }
`;

export const Footer = () => {
  const fullYear = new Date().getFullYear();

  return (
    <div css={containerStyle}>
      <div>
        <svg
          preserveAspectRatio="xMidYMax meet"
          css={svgStyle}
          viewBox="0 0 1600 200"
          data-height="200"
        >
          <path
            style={{ opacity: 1, fill: "#6c7a89" }}
            d="M1615.6,58.4c-8.6,20.7-19.2,31.4-26.8,36.8c-4.4-7.6-8.7-18.4-10.9-33.4
            c-9.2,22.6-20.9,33.4-28.6,38.3c-2.4-7.7-4.4-16.4-6-26.2c-10.4,25.9-22.8,42.1-33.4,52.2c-2.8-6.5-5.2-14.5-6.8-24.4
            c-5.2,13.2-11.2,22.4-16.8,28.8c-3.9-5.1-8.1-12.1-11.9-21.5c6.8,0.7,13.6,1.4,20.4,2.1c0,0-20-9.9-26.6-49.3
            c-4.6,12-10,20.7-15.2,27c-8.1-12.1-16.5-30.2-21.1-56.8c-15.1,39.7-35.4,57-47.2,64.2c-3.8-7.1-7.4-16.7-9.7-29.2
            c-8.5,22.9-19.8,34-27.3,39.2c-8.5-11.9-17.5-30.1-22.7-57.5c-8.3,22.9-18.3,38.4-27.6,48.9c-5.4-7.4-11.2-18.9-14.5-36.1
            c-7.8,22-18.2,33.2-25.7,38.8c-2.7-4.1-5.5-9-8-14.9c6.8,0.5,13.6,1,20.4,1.4c0,0-20.4-9.2-28.3-48.4
            c-8.5,24.4-20.4,35.7-27.8,40.6c-9-11.7-18.8-30-24.7-58c-17,50.2-43.2,65.9-51.6,69.7c-7.3-11.5-14.5-27.5-19.4-49.5
            c-9.3,28.5-21.7,46-32.1,56.5c-4.5-7.1-9-17-12.1-30.5c-5.7,18-13.4,29-19.9,35.6c-8.8-11.5-18.2-29.1-24.3-55.3
            c-5.6,17.9-12.4,31.5-19.2,41.8c-6.8-5.6-17.6-18-23.7-43.2c-4.8,15.8-11.2,26.3-17.1,33.2c-9.3-5.7-32.3-24.1-44.6-72.7
            c-8.8,29.7-21.1,47.7-31.5,58.5C889,58.6,885,49.8,882,38.2c-11,38.5-32,46-32,46c6.8,0.1,13.6,0.1,20.4,0.2
            c-8.5,15.8-17.6,23.9-23.2,27.7c-7.1-7.5-15-18-22.5-32.6c10.8,0,21.7,0.1,32.5,0.2c0,0-33.2-12.5-49.9-74
            c-7.2,26.2-17.4,43.4-26.7,54.6c-4.1-6.6-8.2-15.2-11.3-26.6c-2.2,8.3-4.9,15.2-7.8,20.9C752,43.5,741.6,26.3,734.1,0
            c-11.6,44.1-31.9,63.1-42.4,70.4c-6.3-6.8-13.5-17.9-18.7-35.6c-6,23.7-16,36-23,41.9c-7.9-10.8-16-25.9-22.3-46.9
            c-5.8,23.6-14.1,40.1-22.2,51.6c-3.5-5.9-6.9-13.4-9.7-22.6c-8.3,34.8-25.7,45.2-29.2,47c-0.2,0-0.4,0-0.6,0
            c-6.8-10.2-13.5-23.7-19.1-41.3c-3.4,14.8-7.9,26.9-12.7,36.6c-7.3-5.2-18.6-16.8-26.4-41C505,72.7,501,82,496.8,89
            c-7.9-10.6-16-25.2-22.6-45.3c-4.7,21.5-11.7,37.2-18.8,48.6c-6.9-6.4-15.3-17.5-21.6-36.4c-5.7,26.8-17.1,39.4-23.7,44.7
            c-10.5-10.4-22.8-27.6-32.4-55.4c-3.9,19.2-9.7,33.9-15.8,45c-6.6-6.5-14.4-17.2-20.5-34.5c-2.2,11.3-5.5,20-9,26.8
            c-11.9-8.4-29.9-26.2-43.1-63.1c-12.1,62.6-44.2,77.5-44.3,77.6c10.8-0.9,21.7-1.8,32.5-2.7c-9,21.2-19.4,34.5-27.5,42.5
            c-5.8-6.5-12.1-16.2-17.3-30.4c-0.8,4.3-1.7,8.3-2.8,12c-7.2-4-20.7-14.7-30.6-41.1c-6.9,39.4-27,49-27,49
            c6.8-0.7,13.5-1.3,20.3-1.9c-0.9,2.1-1.8,4.1-2.7,6c-11.9,1.1-23.8,2.3-35.7,3.5c-7-3.7-21.1-14.1-31.6-41
            c-4.5,27.3-15.4,40.4-21.7,45.9c-5.9-6.4-12.3-15.8-17.7-29.7c-1.5,9.8-3.9,17.7-6.6,24.2C66.7,123,55.2,107.3,45.5,83.1
            c-2.2,14.5-5.6,26.5-9.5,36.4c-7.5-5.7-17.5-16.3-25.7-36.3C9.3,90.4,7.8,96.6,6,101.9c-10.5-10-22.5-25.7-32.8-50.4
            c-0.4,2.5-0.7,4.9-1.2,7.2c7.3,57.9,10.2,77.5,17.5,135.4l1619.5-1.6c6.2-49.3,7.4-58.7,13.6-108
            C1619.7,77.7,1617.1,69.1,1615.6,58.4z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M85.8,155c5.1-0.6,10.1-1.1,15.2-1.7c0,0-13.6-3.6-27.3-24c5.9-0.7,11.7-1.3,17.6-1.9
            c0,0-18.8-4.7-31.6-37c-5.3,34.4-22.6,43.1-22.6,43.1c5.9-0.7,11.7-1.4,17.6-2C46,154.4,33.5,161,33.5,161
            c5.1-0.6,10.1-1.2,15.2-1.8c-11.7,23-25.4,30-25.5,30l94.1-10.6C117.3,178.6,102.4,174.9,85.8,155z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M367.5,131.9c4.7-0.3,9.5-0.6,14.2-0.9c0,0-12.6-3.9-24.5-23.6c5.5-0.4,11-0.7,16.5-1.1
            c0,0-17.3-5.1-28-35.9c-6.3,32-22.8,39.4-22.8,39.4c5.5-0.4,11-0.8,16.5-1.2c-9.1,21.1-21,26.7-21,26.7c4.7-0.4,9.5-0.7,14.2-1
            c-11.9,21.1-25,27.1-25,27.1l88.3-6.1C396,155.3,382.2,151.1,367.5,131.9z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M714.6,108.9c6.5-0.1,13-0.2,19.5-0.2c0,0-16.8-6.3-31.7-33.9c7.5-0.1,15.1-0.2,22.6-0.3
            c0,0-23.3-8.2-35.7-50.9c-11,43.1-33.9,52.1-33.9,52.1c7.5-0.2,15.1-0.3,22.6-0.5c-13.9,28.1-30.5,34.9-30.5,34.9
            c6.5-0.2,13-0.3,19.5-0.4c-17.7,27.9-35.9,35.1-35.9,35.1l120.6-2.1C751.7,142.8,733.3,136.1,714.6,108.9z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M997.7,115.2c5.7,0.2,11.5,0.3,17.2,0.5c0,0-14.6-6.2-26.7-31.1c6.7,0.2,13.3,0.4,20,0.6
            c0,0-20.2-8.2-29.5-46.3c-11.3,37.6-31.9,44.7-31.9,44.7c6.7,0.1,13.3,0.3,20,0.5c-13.4,24.3-28.3,29.6-28.3,29.7
            c5.7,0.1,11.5,0.2,17.2,0.4c-16.7,23.9-33,29.6-33,29.6l106.5,2.8C1029.2,146.5,1013.1,139.9,997.7,115.2z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M1224.6,124.7c5.9,0.4,11.9,0.8,17.8,1.2c0,0-15-6.9-26.6-33.2c6.9,0.4,13.8,0.9,20.7,1.3
            c0,0-20.7-9.2-29-49.1c-13.1,38.6-34.7,45.2-34.7,45.2c6.9,0.4,13.8,0.8,20.7,1.2c-14.7,24.7-30.4,29.7-30.4,29.7
            c5.9,0.3,11.9,0.7,17.8,1c-18.1,24.2-35.3,29.5-35.3,29.5l110.4,6.7C1256.1,158.3,1239.7,150.9,1224.6,124.7z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M1357.2,144.3c4.1,0.3,8.2,0.7,12.3,1.1c0,0-10.2-5-17.9-23.3c4.8,0.4,9.5,0.8,14.3,1.2
            c0,0-14.1-6.6-19.2-34.3c-9.6,26.5-24.6,30.7-24.6,30.7c4.8,0.4,9.5,0.8,14.3,1.1c-10.5,16.8-21.4,20.1-21.4,20.1
            c4.1,0.3,8.2,0.6,12.3,1c-12.9,16.4-24.9,19.8-24.9,19.8l76.2,6.3C1378.5,168,1367.2,162.7,1357.2,144.3z"
          />
          <path
            style={{ opacity: 1, fill: "#95a5a6" }}
            d="M1593.7,159.5c5.8,0.7,11.6,1.4,17.4,2.1c0,0-14.3-7.6-24.2-34c6.8,0.8,13.5,1.6,20.3,2.5
            c0,0-19.8-10.2-25.7-49.7c-15,37.1-36.6,42.4-36.6,42.4c6.8,0.8,13.5,1.5,20.3,2.3c-15.8,23.4-31.5,27.5-31.5,27.5
            c5.8,0.7,11.6,1.3,17.4,2c-19.1,22.7-36.3,27-36.3,27l104,12.2c1.3,0.2,2.7,0.3,4,0.5C1622.7,194.2,1607,186,1593.7,159.5z"
          />
          <path
            style={{ fill: "#0061cd" }}
            d="M1475.4,177c0,0-16.4-8.1-30.8-35.3c6,0.6,12.1,1.2,18.1,1.8
            c0,0-15-7.6-25.8-34.8c7,0.7,14,1.4,21,2.1c0,0-20.7-10.1-27.7-51c-14.7,38.8-36.9,44.7-37,44.7c7,0.6,14,1.3,21,2
            c-15.9,24.6-32,29.2-32,29.2c6,0.5,12.1,1.1,18.1,1.7c-19.3,24-37,28.8-37,28.8c-10.7-0.9-21.3-1.9-32-2.7c0,0-18.1-8.4-34.4-37.7
            c6.6,0.5,13.2,1,19.7,1.6c0,0-16.5-7.9-29-37.2c7.6,0.6,15.3,1.2,22.9,1.8c0,0-22.8-10.5-31.5-54.8c-15,42.6-39.1,49.6-39.1,49.7
            c7.7,0.5,15.3,1.1,23,1.6c-16.7,27.2-34.1,32.6-34.1,32.6c6.6,0.4,13.2,0.9,19.7,1.3c-20.4,26.6-39.5,32.3-39.5,32.3
            c-10.7-0.7-21.4-1.3-32.1-2c0,0-12.9-5.6-24.8-26c4.6,0.3,9.3,0.5,13.9,0.8c0,0-11.7-5.3-20.9-25.8c5.4,0.3,10.8,0.6,16.1,0.9
            c0,0-16.2-7-22.9-38.1c-9.9,30.3-26.7,35.6-26.7,35.6c5.4,0.3,10.8,0.5,16.1,0.8c-11.3,19.4-23.5,23.4-23.5,23.4
            c4.6,0.2,9.3,0.4,13.9,0.7c-14,19-27.4,23.3-27.4,23.3c-53.5-2.4-107-4.2-160.5-5.3c0,0-21.3-8.4-42.1-40.7
            c7.6,0.1,15.1,0.2,22.7,0.4c0,0-19.4-7.9-35.9-40.5c8.8,0.1,17.6,0.2,26.4,0.4c0,0-26.9-10.4-40.1-60.5
            c-14.1,49.9-41.2,59.7-41.2,59.7c8.8,0,17.6,0.1,26.4,0.1c-17.1,32.3-36.6,39.8-36.7,39.8c7.6,0,15.1,0,22.7,0.1
            c-21.4,32-42.8,39.9-42.9,39.9c-46.2,0.1-92.3,0.6-138.5,1.7c0,0-20.7-7.1-41.9-37.2c7.2-0.2,14.5-0.4,21.7-0.6
            c0,0-18.9-6.7-36-37.3c8.4-0.3,16.8-0.5,25.3-0.7c0,0-26.1-8.8-40.8-56.2c-11.5,48.3-36.9,58.8-36.9,58.8c8.4-0.3,16.8-0.7,25.3-1
            c-15,31.6-33.4,39.6-33.4,39.6c7.2-0.3,14.5-0.6,21.7-0.9c-19.2,31.4-39.3,39.9-39.4,39.9c-12.7,0.6-25.4,1.2-38.1,1.8
            c0,0-17.2-5.4-35.2-29.6c5.9-0.3,11.9-0.6,17.8-1c0,0-15.6-5.1-30.3-29.8c6.9-0.4,13.8-0.8,20.7-1.1c0,0-21.6-6.7-34.6-45.3
            c-8.4,39.9-29,49-29,49c6.9-0.4,13.8-0.9,20.7-1.3c-11.7,26.3-26.6,33.2-26.6,33.2c5.9-0.4,11.9-0.8,17.8-1.1
            c-15.1,26.2-31.5,33.5-31.5,33.5c-40.1,2.7-80.1,5.8-120.2,9.2c0,0-22.1-6.1-46.2-36c7.5-0.7,15.1-1.4,22.6-2.1
            c0,0-20.2-5.8-40-36.5c8.8-0.8,17.6-1.6,26.3-2.4c0,0-27.9-7.4-46.3-55.9C156.2,83.3,130.3,96,130.3,96c8.8-0.9,17.6-1.8,26.3-2.7
            c-13.6,34-32.3,43.5-32.3,43.5c7.5-0.8,15.1-1.6,22.6-2.3c-17.9,34.1-38.4,44.2-38.4,44.3c-38,4.1-75.9,7.6-113.8,12.3
            c0.4,3.3,0.8,7.6,1.3,10.9c0,13.3,1608,13.3,1608,0c0.4-3.3,0.8-7.6,1.3-10.9C1562,185.6,1518.8,181.6,1475.4,177z"
          />
        </svg>
      </div>
      <div css={responsiveBodyStyle}>
        <FooterContainer>
          <Section>
            <Logo />
            <Text>
              Email:{" "}
              <a href="mailto:info@modestmaple.com">george@modestmaple.com</a>
            </Text>
          </Section>
          <Section>
            <Title order={1} size={18}>
              Navigation
            </Title>
            <br />
            <VerticalStack>
              <NavLinks variant="plain" />
            </VerticalStack>
          </Section>
        </FooterContainer>
      </div>
      <div css={copyright}>
        <Text color="white">
          Copyright © {fullYear} Modest Maple Web Developement
        </Text>
      </div>
    </div>
  );
};
