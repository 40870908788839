import { MantineSize, Text as MantineText, TextProps as MantineTextProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

type TextProps = {
  size?: MantineSize;
  color?: MantineTextProps['c'];
  bold?: boolean;
  children: ReactNode;
};

export const Text: FC<TextProps> = ({ children, color, bold = false, ...props }) => {
  const fontWeight = bold ? 700 : 600;

  return (
    <MantineText c={color} {...props} fw={fontWeight}>
      {children}
    </MantineText>
  );
};
