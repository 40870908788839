import { Group, GroupProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

type HorizontalStackProps = {
  justify?: GroupProps['justify'];
  children?: ReactNode;
};

export const HorizontalStack: FC<HorizontalStackProps> = ({ children, ...props }) => (
  <Group {...props}>{children}</Group>
);
