import {
  SimpleGrid as MantineSimpleGrid,
  SimpleGridProps as MantineSimpleGridProps,
} from '@mantine/core';
import { FC, ReactNode } from 'react';

type SimpleGridProps = {
  spacing?: MantineSimpleGridProps['spacing'];
  cols?: MantineSimpleGridProps['cols'];
  children: ReactNode;
};

export const SimpleGrid: FC<SimpleGridProps> = ({ children, ...props }) => (
  <MantineSimpleGrid {...props}>{children}</MantineSimpleGrid>
);
