import { css } from "@emotion/react";
import { FC, ReactNode } from "react";

const glassStyle = css({
  padding: "12px",
  margin: "12px",
  borderRadius: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.24)",
  boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.25)",
  backdropFilter: "blur(4px)",
  paddingRight: "20px", // Add extra padding to the right
});

export const GlassBackground: FC<{ children: ReactNode }> = ({ children }) => (
  <div css={glassStyle}>{children}</div>
);
